import { PropsWithChildren, useState, CSSProperties, ReactNode } from 'react';

const details = [
    {
        title: 'AtelierMC là gì ?',
        details: 'AtelierMC hiện tại là một máy chủ sinh tồn kết nối nhiều người chơi. Những người chơi sẽ có khả năng tương tác với nhau và hoạt động cùng nhau để cùng hoàn thành các mục tiêu sinh tồn hoặc mục tiêu của bản thân. Thường phổ biến ở các nhóm bạn chơi chung với nhau ở một server riêng tư.'
    },
    {
        title: 'Mình có được chào đón ở Atelier không ?',
        details: 'Mục tiêu của Atelier là xây dựng một cộng đồng có thể nhỏ nhưng tâm huyết. Bản chất nghiện của con người có thể tạo ra những thứ lớn lao và AtelierMC là nơi để hỗ trợ điều đó. Tất cả mọi người đều được chào đón ở AtelierSMP, cam kết nuôi lớn cộng đồng đến vô biên.'
    },
    {
        title: 'Làm sao để có thể tham gia máy chủ Atelier ?',
        details: 'Để có thể tham gia được máy chủ AtelierMC, bạn cần có một tài khoản Discord để có thể liên kết nó với username Minecraft của mình. Ở Atelier, mọi người đều giống như mọi người, chỉ cần bạn là một người thân thiện, chắc chắn ai cũng sẽ chào đón bạn B)'
    },
    {
        title: 'Mình có thể biết thêm về máy chủ không ?',
        details: 'Máy chủ hiện tại chạy trên máy chủ vật lí riêng đảm nhận phát triển bởi Trần Minh Thuận, vốn anh ấy là một người không muốn nghiện game nữa nên quyết định cho con PC mình ra đi để không phải động vào nó lần nữa. Rất đáng buồn điều này phản tác dụng và chúng ta có AtelierMC như hôm nay.'
    },
    {
        title: 'Hiệu năng của máy chủ có tốt không ?',
        details: (
            <>
                Máy chủ hiện tại là máy chủ vật lí nên tụi mình có thể làm bất cứ điều gì mà VPS không thể mang lại. Mang lại khả năng hiệu chỉnh chi tiết đến từng bit. Hiện tại máy chủ đang có 3 máy chủ vật lí.
                <br />
                <br />
                ◊ Máy chủ SMP hiện là cụm máy chủ chính là AtelierSMP. Hiện là máy chủ khỏe nhất trong cụm. Chúng tôi cam kết ở mức 40 người chơi một lúc mà không tụt TPS. Spec cơ bản: i5-4570, 16GB, 240GB SSD. Tương lai vẫn còn một nấc i7 4790K vì Optiplex Lìe
                <br />
                <br />
                ◊ Máy chủ proxy thứ nhất tài trợ bởi PluginDZN, phụ trách gánh kết nối và packet. Hiện tồn tại trên Intel G2030 4GB RAM.
                <br />
                ◊ Máy chủ proxy thứ hai tài trợ bởi mayznot, phụ trách gánh kết nối thứ 2 trường hợp proxy trên overload hoặc cúp điện. tồn tại trên i3 2120 và 8GB RAM.
                <br />
                ◊ Một máy chủ nho nhỏ khác, theo kế hoạch sẽ là nơi chạy web Atelier này được tài trợ bởi Coffene, một staff ở Resolved Replays. Hiện đang là Celeron khá yếu. nhưng chúng tôi có thể làm nó thành Database.
            </>
        )
    }
]

function DetailsAccordion(
    { trigger, children, style, className }:
        { trigger: ReactNode, children: PropsWithChildren<{}>['children'], style?: CSSProperties, className?: string }
) {
    let [open, setOpen] = useState(false);
    return (
        <div className={className} style={style}>
            <a onClick={() => setOpen(!open)} className={open ? 'text-sky-500' : ''}>
                {trigger}
            </a>
            {open && children}
        </div>
    )
}

export default () => {
    const linkStyle = "uppercase mr-4 py-2 px-4 hover:text-zinc-400 hover:border-sky-400 hover:border-b-2 font-bold font-opensans";
    return (
        <div>
            <div className="fixed inset-x-0 top-0 z-50 bg-neutral-700 text-white">
                <div className="flex justify-between container mx-auto">
                    <img src="/sekieilogo.png" className="h-20" />
                    <div className="self-center">
                        <a className={linkStyle} href="/">
                            Home
                        </a>
                        <a className={linkStyle} href="https://discord.io/AtelierMC">
                            Discord
                        </a>
                    </div>
                </div>
            </div>
            <div style={{
                backgroundImage: "url('/bg1.jpg')",
                backgroundPosition: '50% 50%'
            }}>
                <img src="atri_transparent.png" className="mt-20 ml-5 w-2/3 animate__animated animate__slideInRight" />
            </div>
            <div className="text-center bg-gradient-2 pt-16">
                <div className="text-4xl lg:text-5xl font-bold">
                    <div className='-mt-80 mb-64 w-full text-right flex justify-end'>
                        <div className="bg-zinc-800 text-white p-4 rounded-lg mr-32 animate__animated animate__fadeIn">
                            AtelierSMP
                        </div>
                    </div>
                    Chào mừng đến với Atelier
                </div>
                <div className="italic text-base text-neutral-700 py-6">
                    A Survival Multi-player Minecraft Server
                </div>
                <div className="text-left mx-16 lg:mx-80">
                    {details.map((entry, index) => (
                        <DetailsAccordion className={"bg-white" + (index ? ' mt-3' : '')} trigger={(
                            <div className="font-bold border-2 border-black p-7 font-opensans cursor-pointer">
                                {entry.title}
                            </div>
                        )}>
                            <div className="p-7 font-opensans">
                                {entry.details}
                            </div>
                        </DetailsAccordion>
                    ))}
                </div>
            </div>
        </div>
    )
}